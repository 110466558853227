<template>
  <main id="contact">
    <img class="prev" src="../assets/chevron-left.svg" alt="next" @click="$emit('move', { transitionType: 'prev', to: '/inspiration' })">
    <section class="content">
      <section class="intro" v-hammer:swipe.left.right="goTo">
        <pager :activePage="6" />
        <img src="@/assets/icon-telephone.svg" alt="contact">
        <h1 class="main">Kontakt</h1>
      </section>
      <section class="floating" v-if="!verified">
        <h1>Jag vill bli kontaktad av en livs levande studie- & yrkesvägledare</h1>
        <form action="#">
          <label for="otherMunicipality"><input type="checkbox" v-model="otherMunicipality.active" name="profile">Jag går i skolan i en annan kommun</label>
          <label for="school" v-if="!otherMunicipality.active">Nuvarande Skola</label>
          <select name="school" v-model="school" v-if="!otherMunicipality.active">
              <option v-for="(school, index) in options" :value="school" :key="index">{{school}}</option>
          </select>
          <label v-if="otherMunicipality.active" for="otherSchool">Nuvarande Skola</label>
          <input v-if="otherMunicipality.active" type="text" name="otherSchool" v-model="otherMunicipality.school">
          <label v-if="otherMunicipality.active" for="municipality">Nuvarande Kommun</label>
          <input v-if="otherMunicipality.active" type="text" name="municipality" v-model="otherMunicipality.municipality">
          <label for="email">Din E-post</label>
          <input type="email" name="email" v-model="email">
<!--          <label for="profile" @click=" profile = !profile"><input type="checkbox" :checked="profile" name="profile">Bifoga min studieprofil till SYV tack</label> -->
          <label for="msg">Ev. meddelande</label>
          <textarea name="msg" rows="10" v-model="msg"></textarea>
          <label for="gdpr" class="gdpr" @click="gdpr = !gdpr"><input type="checkbox" :checked="gdpr" name="gdpr"><span>Jag godkänner behandling av mina uppgifter i enlighets med <a href="https://www.molndal.se/startsida/kommun-och-politik/insyn-och-paverkan/personuppgifter/hantering-av-personuppgifter-inom-utbildningsforvaltningen.html" target="_blank">Mölndals stads GDPR policy.</a></span></label>
          <a href="#" class="btn" :class="{nogdpr : !gdpr || !email}" @click="contactMe">Ta kontakt med mig!</a>
        </form>
      </section>
      <section class="floating" v-if="verified">
        <h1>Vi hörs!</h1>
        <p>Du kommer inom kort få ett mail med tidsförslag av din lokala studie och yrkesvägledare.</p>
        <a href="#" class="btn" @click="$router.push('/')">Ta mig till början!</a>
      </section>
    </section>
  </main>
</template>

<script>

import pager from '@/components/Pager';

export default {
  name: 'contact',
  components: {
    pager
  },
  computed: {
    options(){
      return this.$store.state.formData.syvForm;
    }
  },
  data(){
    return {
      school: '',
      email: '',
      otherMunicipality:{
        active: false,
        municipality: '',
        school: ''
      },
      msg: '',
      profile: true,
      verified: false,
      gdpr: false,
      msgs: []

    }
  },
  methods: {
    contactMe(){
      if(!this.email) {
        this.msgs.push('Du måste ange en epost-adress om du ska kunna bli kontaktad.')
      }

      if(!this.gdpr) {
        this.msgs.push('Du måste godkänna GDPR-policyn för att kunna skicka meddelande.')
      } 
      
      if(!this.msgs.length){
        
        this.$store.dispatch('contact', {
          school: this.school,
          email: this.email,
          msg: this.msg,
          otherMunicipality:{
            active: this.otherMunicipality.active,
            municipality: this.otherMunicipality.municipality,
            school: this.otherMunicipality.school
          },
          profile: this.profile,
        })
          this.verified = true;
      
      } else {
        alert(this.msgs.join(' '));
        this.msgs = [];
      }
    },
    goTo(e){
      if(e.direction === 4){
       this.$emit('move', { transitionType: 'prev', to: '/inspiration' });
      }
    }
    
  }
}
</script>
<style lang="scss">
@import '../scss/variables';

#contact {

  .intro {
    background: $yellow !important;
    flex-direction: column;
    position: relative;

    .pager {
      position: absolute;
      top: 0;
    }

    img {
      max-height: 10vh;
    }

  }

  .floating {
  
    form {
      display: flex;
      flex-direction: column;

      label {
        font-size: .8rem;
        margin: .5rem 0;
        display: flex;
        align-items: center;

        &.gdpr {
          margin: 1rem 0 0 0;
          background: rgba($color: #000000, $alpha: .1);
          border-radius: $radius;
          padding: .5rem;
        }
        
      }

      input,textarea,select {
        appearance: none;
        border: 1px solid rgba($color: #000000, $alpha: .8);
        border-radius: $radius;
        padding: .5rem;
        font-size: 1rem;
        resize: none;
        transition: $transitionTime;

        &[type="checkbox"] {
          margin: .5rem .5rem .5rem 0;

          &:checked {
            background: url('../assets/icon-close.png');
            background-size: contain;
          }

        }

        &:focus {
          outline: none;
          border: 1px solid rgba($color: #000000, $alpha: 1);
          background: rgba($color: #000000, $alpha: .05);
        }
      }

      .btn {
        margin-top: 1.5rem;

        &.nogdpr {
          opacity: .2;  
        }
      }
    }
  }
}

</style>

